.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 120px;
    color: white;
}
.nav-logo{
    font-family: Outfit;
    font-size: 42px;
    font-weight: 500;
}
.nav-menu{
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 15px;
    gap: 90px;
}
.nav-contact{
    border-radius: 50px;
    padding: 10px 38px;
    background: #fff;
    color: #262626; 
}
.nav-menu li{
    cursor: pointer;
}