.hero{
    margin: 0px 120px;
    margin-top: -20px;
}
.hero-text{
    color: #FFF;
    font-size: 60px;
    font-weight: 500;
    line-height: 10px;
    margin-top: 90px;
}
.hero-explore{
    display: flex;
    align-items: center;
    gap: 50px;
    width: fit-content;
    margin-top: 70px;
    padding: 5px 8px;
    padding-left: 30px;
    border-radius: 60px;
    background-color: white;
    cursor: pointer;
}
.hero-explore p{
    color: #292929;
    font-size: 20px;
    font-weight: 500;
}
.hero-dot-play{
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
}
.hero-dots{
    display: flex;
    align-items: center;
    gap: 23px;
    list-style: none;
}
.hero-dot{
    width: 15px;
    height: 15px;
    background: #FFF;
    border-radius: 7.5px;
    cursor: pointer;
}
.hero-dot.orange{
    background: orangered;
}
.hero-play{
    display: flex;
    align-items: center;
    gap: 30px;
}
.hero-play p{
    color: #f5f1f1;
    font-size: 15px;
}